import React from 'react';
import { string } from 'prop-types';
import { Box, AspectRatio, Heading, Text, Button, Center, Link } from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';

import { THE_BRIDE, WEDDING_AKAD_FULLDATE, YOUTUBE_EMBED, YOUTUBE_LINK } from '@/constants';
import { BUTTON_PROPS, BG_PRIMARY_GRADIENT } from '@/constants/colors';
import { IMG_PATTERN_SQUARE } from '@/constants/assets';
import txt from './locales';
import useLang from '@hooks/useLang';

function YoutubeLiveSection({ ...rest }) {
  const lang = useLang();
  return (
    <Box position="relative" bgColor="bgPrimary" {...rest}>
      {/* Title & Desctiption Section */}
      <Box textAlign="center" position="relative" padding="62px 32px 32px 32px">
        <Box
          width="100%"
          padding="24px 16px"
          borderRadius="24px"
          bgSize="initial"
          border="6px solid"
          borderColor="bgSecondary"
          bgColor="bgPrimary"
          backgroundImage={`linear-gradient(${BG_PRIMARY_GRADIENT}, ${BG_PRIMARY_GRADIENT}), url(${IMG_PATTERN_SQUARE})`}
          boxShadow="2xl"
        >
          <WithAnimation>
            <Heading margin="8px 0" fontWeight="normal" size="2xl" color="mainLightText">
              {txt.title[lang]}
            </Heading>
          </WithAnimation>
          <WithAnimation>
            <Text color="mainLightText">
              {`LIVE on ${WEDDING_AKAD_FULLDATE}`} <br />
            </Text>
          </WithAnimation>
          {/* Live stream section */}
          <WithAnimation>
            <AspectRatio
              marginTop="24px"
              maxW="560px"
              ratio={16 / 9}
              borderRadius="lg"
              boxShadow="xl"
            >
              <iframe
                title={`Live wedding of ${THE_BRIDE}`}
                src={YOUTUBE_EMBED}
                allowFullScreen
                loading="lazy"
                style={{ borderRadius: '16px' }}
              />
            </AspectRatio>
          </WithAnimation>
          <WithAnimation>
            <Text textAlign="center" fontSize="sm" color="mainLightText" margin="32px 0 16px 0">
              {txt.problem[lang]}
            </Text>
          </WithAnimation>
          <Center>
            <Link href={YOUTUBE_LINK} target="_blank" _hover={{ textDecoration: 'none' }}>
              <Button
                size="sm"
                fontWeight="normal"
                variant="solid"
                bgColor="bgSecondary"
                color="alternativeDark"
                marginBottom="16px"
                boxShadow="xl"
                {...BUTTON_PROPS}
              >
                {txt.open[lang]}
              </Button>
            </Link>
          </Center>
        </Box>
      </Box>
    </Box>
  );
}

YoutubeLiveSection.propTypes = {
  lang: string.isRequired,
};

export default React.memo(YoutubeLiveSection);
